import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

const ZoneDeReconfort = () => {
  const metaTitle = 'Zone de réconfort | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Zone de réconfort"
      />
      <div className="container content pb-12">
        <div>

          {/* ZONE DE RÉCONFORT */}
          <div>
            <h2>Zone de réconfort</h2>
            <p>Présence d’organismes communautaires et de l'infirmerie pour créer un Safe space pour supporter les festivalier·ères durant leur séjour.</p>
            <br/>
            <div className='ul-container'>
              <ul>
                <li>Mercredi 16 juillet de 17h à 1h</li>
                <li>Jeudi 17 juillet de 12h à 3h</li>
                <li>Vendredi 18 juillet de 12h à 3h</li>
                <li>Samedi 19 juillet de 12h à 3h</li>
                <li>Dimanche 20 juillet : fermé</li>
              </ul>
            </div>
          </div>

          <div>
            <h3>Sabsa</h3>
            <p>La Coopérative de solidarité déploiera son unité mobile d’analyse des drogues. Trois technologies seront utilisées dans l’unité mobile. Une fois les résultats connus, des intervenants de SABSA fourniront des conseils aux différents usagers, toujours dans l'objectif d'assurer une consommation sécuritaire.</p>
            <div className="mt-3">
              <a href="https://www.sabsa.ca/fr/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] pt-2 invert'}
                              src={'../../images/partenaires/sabsa.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo S.A.B.S.A"/>
              </a>
            </div>

            <h3>Vision d’Espoir de Sobriété</h3>
            <p>Cet organisme offre des services d’accueil, d’information, d’orientation et d’accompagnement pour les personnes ayant un trouble d'usage de substances en voie de réadaptation.</p>
            <div className="mt-3">
              <a href="https://www.visiondespoirdesobriete.com/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/visiondespoir.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo Vision d'Espoir"/>
              </a>
            </div>

            <h3>CALACS</h3>
            <p>CALACS sera sur place pour prévenir les violences à caractère sexuel et pour accompagner les victimes et leurs proches, tout en faisant de la sensibilisation du public pour enrayer toutes les formes de violences sexuelles.</p>
            <div className="mt-3">
              <a href="http://www.rqcalacs.qc.ca/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/rqcalacs.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo C.A.L.A.C.S"/>
              </a>
            </div>

            <h3>Ambulance St-Jean</h3>
            <p>Ambulance Saint-Jean, organisme humanitaire international reconnu comme la norme d’excellence canadienne en matière de services de secourisme, sera présent sur tous les sites pour assurer une aide médicale rapide, professionnelle et bienveillante, et ce, tout au long du festival.</p>
            <div className="mt-3">
              <a href="https://www.ctaq.com/en/home/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/ambulancestjean.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo Ambulance Saint-Jean"/>
              </a>
            </div>

            <h3>Ressource Genesis</h3>
            <p>Ressource Genesis, organisme engagé dans le soutien aux personnes confrontées à des problématiques de dépendance, sera présent au Festif! pour offrir son accompagnement et ses services de soutien. Leur équipe sera disponible tout au long du festival pour offrir écoute et conseils, dans un environnement bienveillant et respectueux. Grâce à leur expertise et à leur approche humaine, Ressource Genesis contribue à créer un espace où chacun peut se sentir en sécurité et soutenu pendant l'événement.</p>
            <div className="mt-3">
              <a href="https://www.facebook.com/ressourcegenesis" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/ressource_genesis.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo Ressource Genesis"/>
              </a>
            </div>

            <h3>La Marée</h3>
            <p>La Marée a pour mission d'accompagner et soutenir les proches d'une personne vivant une problématique en santé mentale.</p>
            <div className="mt-3">
              {/* <a href="/" target="_blank" rel="noreferrer">
                <StaticImage className={'max-w-[250px] max-h-[100px] md:px-[60px] px-[10px] invert'}
                              src={'../../images/partenaires/logo.png'}
                              placeholder="none"
                              objectFit="contain"
                              alt="Logo La Marée"/>
              </a> */}
            </div>

          </div>
        </div>
      </div>
    </Wolflayout>
  )
}

export default ZoneDeReconfort
